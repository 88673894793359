import React, { useState, useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import GeneralCkeditor from "../../components/GeneralComponents/GeneralCkeditor";
import {
  Button,
  Input,
  FormHelperText,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { s3baseUrl } from "../../config/config";
import { _general_Image_upload } from "../../DAL/general_image_upload/general_Image_upload";
import {
  _add_home_settings_admin,
  _get_home_settings_admin,
} from "../../DAL/website_setting/home_setting";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";

function HomeSetting() {
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isbanner1Loading, setIsbanner1Loading] = useState(true);
  const [isbanner2Loading, setIsbanner2Loading] = useState(true);
  const [isbanner3Loading, setIsbanner3Loading] = useState(true);
  const { userInfo, privilegeAccess } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [previewsBanner1, setPreviewsBanner1] = useState();
  const [previewsBanner2, setPreviewsBanner2] = useState();
  const [previewsBanner3, setPreviewsBanner3] = useState();
  const [inputs, setInputs] = useState({
    banner_1_image: "",
    banner_2_image: "",
    banner_3_image: "",
    banner_1_description: "",
    banner_2_description: "",
    banner_3_description: "",
    feature_description: "",
    on_sale_description: "",
    most_popular_description: "",
  });

  const fileChangedHandlerBanner1 = async (event) => {
    if (event.target.files[0]) {
      setIsbanner1Loading(true);
      const result = await _general_Image_upload(event.target.files[0], "200");
      if (result.code === 200) {
        setPreviewsBanner1(result.path);
        setInputs({
          ...inputs,
          banner_1_image: result.path,
        });
      }
      setIsbanner1Loading(false);
    }
  };
  const fileChangedHandlerBanner2 = async (event) => {
    if (event.target.files[0]) {
      setIsbanner2Loading(true);
      const result = await _general_Image_upload(event.target.files[0], "200");
      if (result.code === 200) {
        setPreviewsBanner2(result.path);
        setInputs({
          ...inputs,
          banner_2_image: result.path,
        });
      }
      setIsbanner2Loading(false);
    }
  };
  const fileChangedHandlerBanner3 = async (event) => {
    if (event.target.files[0]) {
      setIsbanner3Loading(true);
      const result = await _general_Image_upload(event.target.files[0], "200");
      if (result.code === 200) {
        setPreviewsBanner3(result.path);
        setInputs({
          ...inputs,
          banner_3_image: result.path,
        });
      }
      setIsbanner3Loading(false);
    }
  };

  const get_home_setting_admin_list = async () => {
    setIsLoading(true);
    setIsbanner1Loading(true);
    setIsbanner2Loading(true);
    setIsbanner3Loading(true);
    const result = await _get_home_settings_admin();
    if (result.code === 200) {
      setInputs(result.home_settings);
      if (result.home_settings.banner_1_image) {
        setPreviewsBanner1(result.home_settings.banner_1_image);
      }
      if (result.home_settings.banner_2_image) {
        setPreviewsBanner2(result.home_settings.banner_2_image);
      }
      if (result.home_settings.banner_3_image) {
        setPreviewsBanner3(result.home_settings.banner_3_image);
      }
      setIsLoading(false);
      setIsbanner1Loading(false);
      setIsbanner2Loading(false);
      setIsbanner3Loading(false);
    } else {
      setIsLoading(false);
      setIsbanner1Loading(false);
      setIsbanner2Loading(false);
      setIsbanner3Loading(false);
    }
  };

  useEffect(() => {
    get_home_setting_admin_list();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userInfo.type == 2) {
      if (
        privilegeAccess.add_home_setting === true ||
        privilegeAccess.edit_home_setting === true
      ) {
        setIsButtonLoading(true);
        const result = await _add_home_settings_admin({
          home_settings: inputs,
        });

        if (result.code === 200) {
          setIsLoading(true);
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
          setIsButtonLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
          setIsButtonLoading(false);
        }
      }
    } else {
      setIsButtonLoading(true);
      const result = await _add_home_settings_admin({
        home_settings: inputs,
      });

      if (result.code === 200) {
        setIsLoading(true);
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        setIsButtonLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
        setIsButtonLoading(false);
      }
    }
  };

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  //===================================================================

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            <h3 className="my-3">Home Setting</h3>
          </div>
        </div>
        {userInfo.type == 2 && !privilegeAccess.view_home_setting ? (
          <NoAccessData />
        ) : (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data">
            <div className="row">
              {/* Banner 1 image*/}
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100 div-style ms-0 pt-0 px-0">
                  <div className="col-5">
                    {/* <h4 style={{ fontSize: "1rem" }}>Banner 2 Image</h4> */}
                    <p className="home_page_header">Banner 1 Image</p>
                    <FormHelperText className="pt-0">
                      Recommended Size(200 X 200) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3">
                    <div className="col-3">
                      {isbanner1Loading ? (
                        <CircularProgress className="centerd" color="primary" />
                      ) : (
                        <img src={s3baseUrl + previewsBanner1} height={50} />
                      )}
                    </div>
                  </div>
                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-file-banner-1">
                      <Input
                        accept="image/*"
                        id="contained-button-file-banner-1"
                        multiple
                        type="file"
                        sx={{ display: "none" }}
                        name="banner_1_image"
                        onChange={fileChangedHandlerBanner1}
                      />
                      <Button
                        id="contained-button-file-banner-1"
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span">
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
              {/* Banner 2 image*/}
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100 div-style ms-0 pt-0 px-0">
                  <div className="col-5">
                    <p className="home_page_header">Banner 2 Image</p>
                    <FormHelperText className="pt-0">
                      Recommended Size(200 X 200) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3">
                    <div className="col-3">
                      {isbanner2Loading ? (
                        <CircularProgress className="centerd" color="primary" />
                      ) : (
                        <img src={s3baseUrl + previewsBanner2} height={50} />
                      )}
                    </div>
                  </div>
                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-file-banner-2">
                      <Input
                        accept="image/*"
                        id="contained-button-file-banner-2"
                        multiple
                        type="file"
                        sx={{ display: "none" }}
                        name="banner_2_image"
                        onChange={fileChangedHandlerBanner2}
                      />
                      <Button
                        id="contained-button-file-banner-2"
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span">
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
              {/* Banner 3 image */}
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100 div-style ms-0 pt-0 px-0">
                  <div className="col-5">
                    <p className="home_page_header">Banner 3 Image</p>
                    {/* <h4 style={{ fontSize: "1rem" }}>Banner 3 Image</h4> */}
                    <FormHelperText className="pt-0">
                      Recommended Size(200 X 200) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3">
                    <div className="col-3">
                      {isbanner3Loading ? (
                        <CircularProgress className="centerd" color="primary" />
                      ) : (
                        <img src={s3baseUrl + previewsBanner3} height={50} />
                      )}
                    </div>
                  </div>
                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-file-banner-3">
                      <Input
                        accept="image/*"
                        id="contained-button-file-banner-3"
                        multiple
                        type="file"
                        sx={{ display: "none" }}
                        name="banner_3_image"
                        onChange={fileChangedHandlerBanner3}
                      />
                      <Button
                        id="contained-button-file-banner-2"
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span">
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mb-4">
                <p className="home_page_header">Banner 1 Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="banner_1_description"
                  editorHeight={350}
                />
              </div>
              <div className="col-12 mb-4">
                <p className="home_page_header">Banner 2 Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="banner_2_description"
                  editorHeight={350}
                />
              </div>
              <div className="col-12 mb-4">
                <p className="home_page_header">Banner 3 Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="banner_3_description"
                  editorHeight={350}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mb-4">
                <p className="home_page_header">Feature Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="feature_description"
                  editorHeight={350}
                />
              </div>
              <div className="col-12 mb-4">
                <p className="home_page_header">On Sale Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="on_sale_description"
                  editorHeight={350}
                />
              </div>
              <div className="col-12 mb-4">
                <p className="home_page_header">Most Popular Description</p>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  required
                  name="most_popular_description"
                  editorHeight={350}
                />
              </div>
            </div>

            <div className="col-12 mt-3 text-end">
              <Tooltip
                title={
                  userInfo.type == 2 &&
                  (privilegeAccess.edit_home_setting ||
                    privilegeAccess.add_home_setting) === false
                    ? "You have no access to perform this action"
                    : ""
                }
                placement="right-end"
                arrow>
                {isButtonLoading ? (
                  <LoadingButton loading variant="contained">
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    // style={{ textTransform: 'capitalize' }}
                    variant="contained"
                    type="submit">
                    Submit
                  </Button>
                )}
              </Tooltip>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default HomeSetting;

import React, { useState, useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import GeneralCkeditor from "../../components/GeneralComponents/GeneralCkeditor";
import {
  Button,
  TextField,
  IconButton,
  Input,
  FormHelperText,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { _general_Image_upload } from "../../DAL/general_image_upload/general_Image_upload";
import { s3baseUrl } from "../../config/config";
import { LoadingButton } from "@mui/lab";
import {
  _add_general_settings,
  _general_setting,
} from "../../DAL/website_setting/general_setting";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import NoAccessData from "../../components/GeneralComponents/NoAccessData";
function GeneralSetting() {
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState();
  const [previewsFavicon, setPreviewsFavicon] = useState();
  const [previewsPaymentImage, setPreviewsPaymentImage] = useState();
  const [previewsProductImage, setPreviewsProductImage] = useState();
  const [isLogoImageLoading, setIsLogoImageLoading] = useState(true);
  const [isFaviconLoading, setIsFaviconLoading] = useState(true);
  const [isPaymentImageLoading, setIsPaymentImageLoading] = useState(true);
  const [isProductImageLoading, setIsProductImageLoading] = useState(true);
  const { userInfo, privilegeAccess } = useContentSetting();

  let [inputs, setInputs] = useState({
    track_your_order_page_heading: "",
    tracking_order_page_heading: "",
    track_order_button_text: "",
    account_page_heading: "",
    login_page_heading: "",
    create_account_page_heading: "",
    meta_title: "",
    meta_description: "",
    support_email: "",
    copy_right_statement: "",
    contact: "",
    title: "",
    address: "",
    logo_image: "",
    favicon_image: "",
    payment_option_image: "",
    product_detail_image: "",
    product_detail_description: "",
    quick_shop: "",
    informations: "",
    customer_services: "",
    free_shipping_and_return: "",
    money_guarantee: "",
    online_support: "",
    secure_payments: "",
    content_settings: {
      profile_page_heading: "",
      faq_page_heading: "",
      payment_return_page_heading: "",
      email_confirmation_page_heading: "",
      code_verification_page_heading: "",
      reset_password_page_heading: "",
      footer_contact_us_heading: "",
      product_page_categories_heading: "",
      product_page_products_heading: "",
      product_page_popular_heading: "",
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    // If the changed input is within the content_settings object
    if (name.startsWith("content_settings.")) {
      const contentSettingsName = name.split(".")[1];
      setInputs((prevInputs) => ({
        ...prevInputs,
        content_settings: {
          ...prevInputs.content_settings,
          [contentSettingsName]: value,
        },
      }));
    } else {
      // If the changed input is outside the content_settings object
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const fileChangedHandler = async (event) => {
    if (event.target.files[0]) {
      setIsLogoImageLoading(true);
      const result = await _general_Image_upload(event.target.files[0], "200");
      if (result.code === 200) {
        setPreviews(result.path);
        setInputs({
          ...inputs,
          logo_image: result.path,
        });
      }
      setIsLogoImageLoading(false);
    }
  };

  const fileChangedHandlerFavicon = async (event) => {
    if (event.target.files[0]) {
      setIsFaviconLoading(true);
      const result = await _general_Image_upload(event.target.files[0], "200");
      if (result.code === 200) {
        setPreviewsFavicon(result.path);
        setInputs({
          ...inputs,
          favicon_image: result.path,
        });
      }
      setIsFaviconLoading(false);
    }
  };
  const fileChangedHandlerPaymentImage = async (event) => {
    if (event.target.files[0]) {
      setIsPaymentImageLoading(true);
      const result = await _general_Image_upload(event.target.files[0], "200");
      if (result.code === 200) {
        setPreviewsPaymentImage(result.path);
        setInputs({
          ...inputs,
          payment_option_image: result.path,
        });
      }
      setIsPaymentImageLoading(false);
    }
  };
  const fileChangedHandlerProductImage = async (event) => {
    if (event.target.files[0]) {
      setIsProductImageLoading(true);
      const result = await _general_Image_upload(event.target.files[0], "200");
      if (result.code === 200) {
        setPreviewsProductImage(result.path);
        setInputs({
          ...inputs,
          product_detail_image: result.path,
        });
      }
      setIsProductImageLoading(false);
    }
  };

  const general_setting_list = async () => {
    setIsLoading(true);
    setIsLogoImageLoading(true);
    setIsFaviconLoading(true);
    setIsPaymentImageLoading(true);
    setIsProductImageLoading(true);
    const result = await _general_setting();
    if (result.code === 200) {
      setInputs(result.general_settings);

      if (result.general_settings.logo_image) {
        setPreviews(result.general_settings.logo_image);
      }
      if (result.general_settings.favicon_image) {
        setPreviewsFavicon(result.general_settings.favicon_image);
      }
      if (result.general_settings.payment_option_image) {
        setPreviewsPaymentImage(result.general_settings.payment_option_image);
      }
      if (result.general_settings.product_detail_image) {
        setPreviewsProductImage(result.general_settings.product_detail_image);
      }
      setIsLoading(false);
      setIsLogoImageLoading(false);
      setIsFaviconLoading(false);
      setIsPaymentImageLoading(false);
      setIsProductImageLoading(false);
    } else {
      setIsLoading(false);
      setIsLogoImageLoading(false);
      setIsFaviconLoading(false);
      setIsPaymentImageLoading(false);
      setIsProductImageLoading(false);
    }
  };

  useEffect(() => {
    general_setting_list();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userInfo.type == 2) {
      if (
        privilegeAccess.add_general_setting === true ||
        privilegeAccess.edit_general_setting === true
      ) {
        setIsButtonLoading(true);
        const result = await _add_general_settings({
          general_settings: inputs,
        });

        if (result.code === 200) {
          setIsLoading(true);
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
          setIsButtonLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
          setIsButtonLoading(false);
        }
      }
    } else {
      setIsButtonLoading(true);
      const result = await _add_general_settings({
        general_settings: inputs,
      });

      if (result.code === 200) {
        setIsLoading(true);
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
        setIsButtonLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
        setIsButtonLoading(false);
      }
    }
  };

  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }
  //===================================================================

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            <h3 className="my-3">General Setting</h3>
          </div>
        </div>
        {userInfo.type == 2 && !privilegeAccess.view_general_setting ? (
          <NoAccessData />
        ) : (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="row">
              <p className="content_setting_page_header">Content Settings</p>
              <div className="col-md-6 col-xs-12 mb-4 mt-1">
                <TextField
                  id="outlined-basic"
                  label="Profile Page Heading"
                  variant="outlined"
                  required
                  sx={{ width: "100%" }}
                  name="content_settings.profile_page_heading"
                  onChange={handleChange}
                  value={inputs?.content_settings?.profile_page_heading}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  required
                  label="FAQs Page Heading"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="content_settings.faq_page_heading"
                  onChange={handleChange}
                  value={inputs?.content_settings?.faq_page_heading}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Payment and Return Page Heading"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="content_settings.payment_return_page_heading"
                  onChange={handleChange}
                  value={inputs?.content_settings?.payment_return_page_heading}
                />
              </div>

              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Email Confirmation Page Heading"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="content_settings.email_confirmation_page_heading"
                  onChange={handleChange}
                  value={
                    inputs?.content_settings?.email_confirmation_page_heading
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Code Verification Page Heading"
                  variant="outlined"
                  required
                  sx={{ width: "100%" }}
                  name="content_settings.code_verification_page_heading"
                  onChange={handleChange}
                  value={
                    inputs?.content_settings?.code_verification_page_heading
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Reset Password Page Heading"
                  variant="outlined"
                  required
                  sx={{ width: "100%" }}
                  name="content_settings.reset_password_page_heading"
                  onChange={handleChange}
                  value={inputs?.content_settings?.reset_password_page_heading}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Footer Contact Us Heading "
                  variant="outlined"
                  required
                  sx={{ width: "100%" }}
                  name="content_settings.footer_contact_us_heading"
                  onChange={handleChange}
                  value={inputs?.content_settings?.footer_contact_us_heading}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Product Page Categories Heading "
                  variant="outlined"
                  required
                  sx={{ width: "100%" }}
                  name="content_settings.product_page_categories_heading"
                  onChange={handleChange}
                  value={
                    inputs?.content_settings?.product_page_categories_heading
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Product Page Products Heading "
                  variant="outlined"
                  required
                  sx={{ width: "100%" }}
                  name="content_settings.product_page_products_heading"
                  onChange={handleChange}
                  value={
                    inputs?.content_settings?.product_page_products_heading
                  }
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Product Page Popular Heading "
                  variant="outlined"
                  required
                  sx={{ width: "100%" }}
                  name="content_settings.product_page_popular_heading"
                  onChange={handleChange}
                  value={inputs?.content_settings?.product_page_popular_heading}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Login Page Heading"
                  variant="outlined"
                  required
                  sx={{ width: "100%" }}
                  name="login_page_heading"
                  onChange={handleChange}
                  value={inputs?.login_page_heading}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  required
                  label="Create Account Page Heading"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="create_account_page_heading"
                  onChange={handleChange}
                  value={inputs?.create_account_page_heading}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Meta Title"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="meta_title"
                  onChange={handleChange}
                  value={inputs?.meta_title}
                />
              </div>

              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Meta Description"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  name="meta_description"
                  onChange={handleChange}
                  value={inputs?.meta_description}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Tracking Order Page Heading"
                  variant="outlined"
                  required
                  sx={{ width: "100%" }}
                  name="tracking_order_page_heading"
                  onChange={handleChange}
                  value={inputs?.tracking_order_page_heading}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Track Your Order Page Heading"
                  variant="outlined"
                  required
                  sx={{ width: "100%" }}
                  name="track_your_order_page_heading"
                  onChange={handleChange}
                  value={inputs?.track_your_order_page_heading}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Track Order Page Button"
                  variant="outlined"
                  required
                  sx={{ width: "100%" }}
                  name="track_order_button_text"
                  onChange={handleChange}
                  value={inputs?.track_order_button_text}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Account Page Heading"
                  variant="outlined"
                  required
                  sx={{ width: "100%" }}
                  name="account_page_heading"
                  onChange={handleChange}
                  value={inputs?.account_page_heading}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Support Email"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="support_email"
                  onChange={handleChange}
                  type="email"
                  required
                  value={inputs?.support_email}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Copyright Statement"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="copy_right_statement"
                  onChange={handleChange}
                  required
                  value={inputs?.copy_right_statement}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Contact No"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="contact"
                  onChange={handleChange}
                  type="number"
                  required
                  value={inputs?.contact}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-4">
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  name="title"
                  onChange={handleChange}
                  value={inputs?.title}
                />
              </div>
            </div>

            <div className="col-12 mb-4">
              <TextField
                id="outlined-multiline-static"
                label="Address"
                multiline
                rows={3}
                name="address"
                onChange={(e) => handleChange(e)}
                inputs={inputs}
                variant="outlined"
                style={{ width: "100%" }}
                required
                value={inputs?.address}
              />
            </div>

            <div className="row">
              {/* Brand Logo */}
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100 div-style ms-0 pt-0 px-0">
                  <div className="col-5">
                    <p className="general_setting_page_header">Brand Logo</p>
                    <FormHelperText className="pt-0">
                      Recommended Size(200 X 100) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3">
                    <div className="col-3">
                      {isLogoImageLoading ? (
                        <CircularProgress className="centerd" color="primary" />
                      ) : (
                        <img src={s3baseUrl + previews} height={50} />
                      )}
                    </div>
                  </div>
                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        sx={{ display: "none" }}
                        name="logo_image"
                        onChange={fileChangedHandler}
                      />
                      <Button
                        id="contained-button-file"
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
              {/* Brand Favicon */}
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100 div-style ms-0 pt-0 px-0">
                  <div className="col-5">
                    <p className="general_setting_page_header">Favicon</p>
                    <FormHelperText className="pt-0">
                      Recommended Size(32 X 32) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3">
                    <div className="col-3">
                      {isFaviconLoading ? (
                        <CircularProgress className="centerd" color="primary" />
                      ) : (
                        <img src={s3baseUrl + previewsFavicon} height={50} />
                      )}
                    </div>
                  </div>

                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-fileFavicon">
                      <Input
                        accept="image/*"
                        id="contained-button-fileFavicon"
                        multiple
                        type="file"
                        sx={{ display: "none" }}
                        name="favicon_image"
                        onChange={fileChangedHandlerFavicon}
                      />
                      <Button
                        id="contained-button-fileFavicon"
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>

              {/* Payment Option Image */}
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100 div-style ms-0 pt-0 px-0">
                  <div className="col-5">
                    <p className="general_setting_page_header">
                      Payment Option Image
                    </p>
                    <FormHelperText className="pt-0">
                      Recommended Size(100 X 50) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3">
                    <div className="col-3">
                      {isPaymentImageLoading ? (
                        <CircularProgress className="centerd" color="primary" />
                      ) : (
                        <img
                          src={s3baseUrl + previewsPaymentImage}
                          height={50}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-filePaymentImage">
                      <Input
                        accept="image/*"
                        id="contained-button-filePaymentImage"
                        multiple
                        type="file"
                        sx={{ display: "none" }}
                        name="payment_option_image"
                        onChange={fileChangedHandlerPaymentImage}
                      />
                      <Button
                        id="contained-button-filePaymentImage"
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>

              {/* Product detail Image */}
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4 employe-profile">
                <div className="row w-100 div-style ms-0 pt-0 px-0">
                  <div className="col-5">
                    <p className="general_setting_page_header">
                      Product detail Image
                    </p>
                    <FormHelperText className="pt-0">
                      Recommended Size(100 X 50) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3">
                    <div className="col-3">
                      {isProductImageLoading ? (
                        <CircularProgress className="centerd" color="primary" />
                      ) : (
                        <img
                          src={s3baseUrl + previewsProductImage}
                          height={50}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-fileProductImage">
                      <Input
                        accept="image/*"
                        id="contained-button-fileProductImage"
                        multiple
                        type="file"
                        sx={{ display: "none" }}
                        name="product_detail_image"
                        onChange={fileChangedHandlerProductImage}
                      />
                      <Button
                        id="contained-button-fileProductImage"
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mb-4">
              <p className="general_setting_page_header">
                Product Detail Description
              </p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="product_detail_description"
                editorHeight={350}
              />
            </div>

            <div className="col-12 mb-4">
              <p className="general_setting_page_header">Quick Shop</p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="quick_shop"
                editorHeight={350}
              />
            </div>
            <div className="col-12 mb-4">
              <p className="general_setting_page_header">Informations</p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="informations"
                editorHeight={350}
              />
            </div>
            <div className="col-12 mb-4">
              <p className="general_setting_page_header">Customer Services</p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="customer_services"
                editorHeight={350}
              />
            </div>

            <div className="col-12 mb-4">
              <p className="general_setting_page_header">
                Free Shipping & Return
              </p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="free_shipping_and_return"
                editorHeight={350}
              />
            </div>

            <div className="col-12 mb-4">
              <p className="general_setting_page_header">Money Guarantee</p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="money_guarantee"
                editorHeight={350}
              />
            </div>

            <div className="col-12 mb-4">
              <p className="general_setting_page_header">Online Support</p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="online_support"
                editorHeight={350}
              />
            </div>

            <div className="col-12 mb-4">
              <p className="general_setting_page_header">Secure Payments</p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                required
                name="secure_payments"
                editorHeight={350}
              />
            </div>

            <div className="col-12 mt-3 text-end">
              <Tooltip
                title={
                  userInfo.type == 2 &&
                  (privilegeAccess.edit_general_setting ||
                    privilegeAccess.add_general_setting) === false
                    ? "You have no access to perform this action"
                    : ""
                }
                placement="right-end"
                arrow
              >
                {isButtonLoading ? (
                  <LoadingButton loading variant="contained">
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    //    style={{ textTransform: 'capitalize' }}
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </Tooltip>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default GeneralSetting;
